ion-content {
  --background: #fff;
}

ion-content ion-toolbar {
  --background: translucent;
}

ion-card {
  text-align: center;
}

.margin {
  margin-top: 30px;
}

.AboutShimmer {
  height: 280px;
  width: 100%;
  justify-content: center;
  margin-top: 5px;
  border-radius: 7px;
}

.animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.animate-category {
  animation: shimmer 3s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.sc-ion-alert-md {
  text-align: center;
  font-weight: 600;
}

.img-radious {
  width: auto;
  height: 100px;
  border-radius: 7%;
  margin-top: 10%;
}

.img-radious-cart {
  width: auto;
  height: 60px;
  border-radius: 7%;
  margin-top: 10%;
}

.partner-contains {
  display: inherit;
}

.alert-button-group.sc-ion-alert-md {
  justify-content: space-around;
}

ion-note {
  font-size: 18px;
}

ion-col.md.hydrated {
  font-size: 16px;
}

.justify-content {
  justify-content: center;
}

.margin-33 {
  margin-left: 33%;
}

.ProuctShimmer {
  height: 70px;
  width: 98%;
  justify-content: center;
  margin: auto;
  margin-top: 7px;
}

.swiper-pagination-bullet-active {
  background: #fff;
}

.bannerShimmer {
  height: 250px;
  width: 100%;
  justify-content: center;
}

.categoryShimmer {
  height: 50px;
  width: 100%;
  justify-content: center;
  margin: auto;
}

ion-row {
  font-size: 12px;
}

ion-toast {
  text-align: center;
}

ion-item {
  --min-height: 16px;
}

.text-align-center {
  text-align: center;
}

.centerCheckBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.border-solid-ddd {
  border-bottom: solid 1px #ccc;
  background: #fff;
}

.ProuctShimmer {
  height: 70px;
  width: 98%;
  justify-content: center;
  margin: auto;
  margin-top: 5px;
}

.centerCheckBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProuctShimmer {
  height: 70px;
  width: 98%;
  justify-content: center;
  margin: auto;
  margin-top: 5px;
}

.initial-logo {
  position: relative;
  z-index: 999;
  top: -50px;
  align-items: center;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  border: #fff;
  border: 1px solid #ccc;
  text-align: center;
}

.row-color {
  background: #fff;
}

.margin-top-10 {
  margin-top: 10px;
}

.color-select {
  background-color: royalblue;
}

.text-center {
  text-align: center;
}

.neu-button {
  color: #35963d;
  font-size: 1rem;
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0 1.6rem;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  transition: box-shadow ease 150ms;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.6),
    6px 6px 16px rgba(0, 0, 0, 0.2);
}

.neu-button:hover {
  box-shadow: -6px -6px 8px rgba(255, 255, 255, 0.6),
    6px 6px 8px rgba(0, 0, 0, 0.2);
}

.neu-button:active {
  box-shadow: -6px -6px 8px rgba(255, 255, 255, 0), 6px 6px 8px rgba(0, 0, 0, 0),
    inset -6px -6px 8px rgba(255, 255, 255, 0.6),
    inset 6px 6px 8px rgba(0, 0, 0, 0.2);
}

.product-card-size {
  margin-bottom: -1%;
}

.product-col-align-8px {
  margin-top: -8px;
}

.product-col-align-3px {
  margin-top: -3px;
}

.footer-border-modal {
  border-top: solid 2px #eee;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
